<div class="dialog-container">
    <div class="dialog-content d-flex flex-column overflow-hidden">
        <div class="title d-flex justify-content-between align-items-center">
            <div>
                {{chat.name}}
            </div>
            <button class="close" mat-dialog-close="" mat-icon-button tabindex="-1">
                <mat-icon class="fas fa-close"></mat-icon>
            </button>
        </div>
        <div class="d-flex flex-column flex-fill overflow-hidden">
            <mat-list>
                <mat-list-item (click)="muteChat($event)" matRipple>
                    <mat-slide-toggle matListItemIcon [checked]="!chatUser.mute" labelPosition="before"></mat-slide-toggle>
                    <div matListItemTitle>
                        Meldingen {{chatUser.mute ? 'gedempt' : 'ingeschakeld'}}
                    </div>
                </mat-list-item>
                <mat-list-item (click)="onlyAdminMessages($event)" matRipple *ngIf="chatAdmin">
                    <mat-slide-toggle matListItemIcon [checked]="!chat.only_admin_messages" class="pt-2" labelPosition="before"></mat-slide-toggle>
                    <div matListItemTitle>
                        Reageren {{chat.only_admin_messages ? 'uitgeschakeld' : 'ingeschakeld'}}
                    </div>
                    <small matListItemLine>
                        {{chat.only_admin_messages ? 'Alleen beheerders kunnen' : 'Iedereen kan'}}  berichten plaatsen
                    </small>
                </mat-list-item>
                <mat-list-item (click)="leaveChat()" class="curpoint" matRipple *ngIf="chat?.school_type !== 'offer'">
                    <div matListItemTitle>
                        Chat verlaten
                    </div>
                    <small matListItemLine>
                        Chat wordt verwijderd uit je lijst
                    </small>
                </mat-list-item>
                <mat-list-item (click)="archiveChats()" class="curpoint" matRipple [disabled]="!chatAdmin">
                    <div matListItemTitle>
                        Chat {{chat.deleted_at ? 'dearchiveren' : 'archiveren'}}
                    </div>
                    <small matListItemLine>
                        Chat wordt {{chat.deleted_at ? 'gedearchiveerd' : 'gearchiveerd'}}
                    </small>
                </mat-list-item>
                <mat-list-item (click)="editChat()" *ngIf="chatAdmin && chat?.school_type !== 'offer'" class="curpoint" matRipple >
                    <div matListItemTitle>
                        Chat aanpassen
                    </div>
                    <small matListItemLine>
                        Deelnemers toevoegen of verwijderen
                    </small>
                </mat-list-item>
            </mat-list>
            <mat-divider></mat-divider>
            <mat-list *ngIf="chat.chat_classrooms?.length < 1" class="flex-fill overflow-auto">
                <mat-list-item>
                    <div matListItemTitle>
                        Gebruikers
                    </div>
                    <small matListItemLine>
                        Klik op een deelnemer om deze beheerder te maken
                    </small>
                </mat-list-item>


                <ng-container *ngFor="let chatUser of chat.chat_users">
                    <mat-list-item (click)="makeAdmin(chatUser)" [disabled]="!chatAdmin" class="curpoint" matRipple>
                        <div [class.line-through]="chatUser.user?.deleted_at">
                            {{chatUser.user?.name}}
                            <i *ngIf="chatUser.user?.deleted_at">
                                (Verwijderd gebruikersaccount)
                            </i>
                            <i *ngIf="chatUser.admin" class="fas fa-users-cog float-right"></i>
                        </div>
                    </mat-list-item>
                </ng-container>
            </mat-list>
            <mat-list *ngIf="chat.chat_classrooms?.length > 0" class="flex-fill overflow-auto">
                <div mat-subheader>Gebruikers uit de volgende klassen</div>
                <ng-container *ngFor="let classroom of chat.chat_classrooms">
                    <mat-list-item>
                        <div>
                            {{classroom.classroom.classroom_name}}
                        </div>
                    </mat-list-item>
                </ng-container>
            </mat-list>
        </div>
        <button color="primary" mat-fab mat-dialog-close="">
            <mat-icon class="far fa-comments"></mat-icon>
        </button>
    </div>
</div>
